<template>
  <div>
    <mf-search
      v-model="computedSearch"
      autofocus
      class="px-3 pt-3"
      hide-details
      :loading="loading || loadingAccounts"
      placeholder="Buscar rede"
      @keyup.enter="selectFirstIfOne"
    />
    <div class="clients-box mt-6">
      <dynamic-scroller
        v-if="clients.length"
        v-slot="{ item, index, active }"
        class="vue-recycle-scroller scroller direction-vertical"
        :items="clients"
        key-field="id"
        :min-item-size="92"
        @scroll-end="clients.length < 20 ? null : $emit('load-more')"
      >
        <dynamic-scroller-item :active="active" :data-index="index" :item="item">
          <v-list-item :key="`${item.id}-${index}`" tabindex="0" three-line @click="selectClient(item)">
            <v-list-item-content>
              <v-list-item-title>
                <span class="body-1">{{ item.name }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span>{{ item.idExternal }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <div class="multiple-chips">
                  <v-chip
                    class="v-chip--active"
                    :color="accountTag(item.statusAccount).color"
                    small
                    style="font-weight: bolder"
                    :text-color="accountTag(item.statusAccount).textColor"
                    >{{ accountTag(item.statusAccount).label }}</v-chip
                  >
                  <v-chip
                    v-if="item.flags.industry && (item.flags.industry.has_mercapromo || item.flags.industry.only_mercapromo)"
                    class="v-chip--active"
                    color="#9933ff"
                    small
                    style="font-weight: bolder"
                    text-color="white"
                    >Mercapromo</v-chip
                  >
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>chevron_right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </dynamic-scroller-item>
      </dynamic-scroller>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'

import { useStore as mainUseStore } from '@/store'
import { useStore as loginUseStore } from '@/modules/login/store'
import { MUTATION_SELECT_USER_ACCOUNT } from '@/modules/login/graphql'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
export default {
  name: 'ClientsList',
  components: { DynamicScroller, DynamicScrollerItem },
  props: {
    clients: Array,
    search: {
      type: String,
      default: '',
    },
    loadingAccounts: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    benched: 0,
    loading: false,
  }),
  computed: {
    ...mapState(mainUseStore, {
      source: 'getCurrentSource',
      redirectUrlAfterAutentication: 'getRedirectUrlAfterAutentication',
    }),
    computedSearch: {
      get() {
        return this.search
      },
      set(value) {
        this.$emit('update:search', value)
      },
    },
  },
  methods: {
    ...mapActions(loginUseStore, ['getCode']),
    async selectClient(client) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: MUTATION_SELECT_USER_ACCOUNT,
        fetchPolicy: 'no-cache',
        variables: {
          accountId: client.id,
        },
      })

      const payload = {
        source: this.source,
      }
      const { authCode } = await this.getCode(this.$apollo, payload)
      window.location.href = `${this.redirectUrlAfterAutentication}?auth_code=${authCode}`
    },
    selectFirstIfOne() {
      if (this.searchedClients.length === 1) this.selectClient(this.searchedClients[0])
    },
    redirectToSpecificRoute(route) {
      window.localStorage.removeItem('redirectAfterLogin')
      this.$router.push(route)
    },
    // This function is directly imported from the Starlord project, so the colors should match it
    accountTag(status) {
      if (status?.deploying) return { label: 'Implantando', color: '#339af0', textColor: '#FFFFFF' }
      if (status?.done) return { label: 'Implantação concluída', color: '#20c997', textColor: '#FFFFFF' }
      if (status?.churn) return { label: 'Churn', color: '#ff6b6b', textColor: '#FFFFFF' }
      if (status?.impossibleSupport) return { label: 'Suporte Impossível', color: '#adb5bd', textColor: '#000000' }
      if (status?.test) return { label: 'Teste', color: '#339af0', textColor: '#FFFFFF' }
      if (status?.temporaryChurn) return { label: 'Churn Temporário', color: '#FFF176', textColor: '#000000' }
      if (status?.adjusting) return { label: 'Em ajuste', color: '#339af0', textColor: '#FFFFFF' }
      if (status?.deprecated) return { label: 'Plataforma Antiga', color: '#adb5bd', textColor: '#000000' }
      else return { label: 'Sem Status', color: '#adb5bd', textColor: '#000000' }
    },
  },
}
</script>

<style lang="scss">
.clients-box {
  min-height: 400px !important;
  height: 400px !important;
}

.multiple-chips {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.scroller {
  height: 400px !important;
  overflow-y: auto !important;
}
</style>
